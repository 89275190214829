module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Frontend Unicorn","short_name":"Frontend Unicorn","start_url":"/","background_color":"#0e0e0e","theme_color":"#0e0e0e","display":"standalone","icon":"src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d3e9baf1184ea04fe1d0a94e73f0a407"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
